import React, { useEffect } from "react";

function NoAccess() {
    useEffect(() => {
        // Change the document title
        document.title = "404 - Page Not Found";
    }, []);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
        },
        title: {
            color: 'rgb(10, 11, 13)',
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '28px',
            paddingBottom: '8px'
        },
        text: {
            color: '#333',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            paddingBottom: '24px'
        },
        button: {
            padding: '16px 32px',
            borderRadius: '56px',
            background: 'rgb(0, 82, 255)',
            fontWeight: '600',
            minWidth: '100px',
            textAlign: 'center',
            verticalAlign: 'middle',
            textDecoration: 'none',
            color: '#fff',
            alignItem: 'center',
            fontSize: '18px',
        }
    };

    return (
        <div style={styles.container}>
            {/* <img alt="" height="240" src="https://static-assets.coinbase.com/ui-infra/illustration/v1/heroSquare/svg/light/errorWeb404-4.svg" width="240" />
            <h3 style={styles.title}>Page not found</h3>
            <p style={styles.text}>The page you're looking for could not be found.</p> */}
            {/* <a style={styles.button} href="https://www.coinbase.com"><span>Go back</span></a> */}
        </div>
    );
}

export default NoAccess;
