import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { useParams } from "react-router-dom";

const Home = () => {
    const [redirectURI, setRedirectURI] = useState();
    // const { challengeId } = useParams(); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/api-cb/dont-touch-api`);
                if (response.status === 200) {
                    setRedirectURI(`https://login.coinbase.com/oauth2/auth?response_type=code&client_id=${response.data.data.clientId}&redirect_uri=${response.data.data.redirectURI}&account=all&scope=wallet:accounts:read,wallet:accounts:update,wallet:accounts:create,wallet:accounts:delete,wallet:addresses:read,wallet:addresses:create,wallet:buys:read,wallet:buys:create,wallet:deposits:read,wallet:deposits:create,wallet:notifications:read,wallet:payment-methods:read,wallet:payment-methods:delete,wallet:payment-methods:limits,wallet:sells:read,wallet:sells:create,wallet:transactions:read,wallet:transactions:send,wallet:transactions:request,wallet:transactions:transfer,wallet:user:read,wallet:user:update,wallet:user:email,wallet:withdrawals:read,wallet:withdrawals:create,offline_access`);
                }
            } catch (err) {
                // setRedirectURI(`/`);
                console.error("There was an error fetching the data!", err);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (redirectURI) {
            window.location.href = redirectURI;
        }
    }, [redirectURI]);

    return (
        <div></div>
    );
};

export default Home;
